<template>
  <div :class="foldersType">
    <template v-if="foldersType == 'listMode'">
      <div class="itemIcon">
        <el-image
          v-if="content.nodeType == 0"
          style="height:16px;width: 20px;"
          :src="
            content.isDisbale
              ? require('../../../../../../../assets/images/class/folder-icon-disable.png')
              : require('../../../../../../../assets/images/class/folder-icon.png')
          "
          :fit="'fill'"
        ></el-image>
        <el-image
          v-if="content.nodeType == 1 && content.shortInfoVo"
          style="height:20px;width:20px;border-radius: 50%"
          :src="content.shortInfoVo.profilePhoto"
          :fit="'fill'"
        ></el-image>
        <div
          v-if="content.nodeType == 1 && !content.shortInfoVo"
          style="height:20px;width:20px;border-radius: 50%;background: #E8E8E8"
        ></div>
      </div>
      <div
        class="itemText"
        :title="showText"
        v-if="!showInputBlock"
        :style="{
          'max-width': showUngroupedGroups ? '200px' : '250px',
        }"
      >
        <span :style="{ color: highlight ? '#52BD68' : '' }">{{
          showText
        }}</span>
      </div>
      <div class="itemPosition" v-else>
        <input
          class="itemInput"
          type="text"
          v-model="inputContent"
          ref="itemInput"
        />
        <div class="buttonBox">
          <div v-waves class="commonButton confirm" @click.stop="changeName">
            <i class="iconfont_Me icon-check iconStyle"></i>
          </div>
          <div v-waves class="commonButton" @click.stop="closeInput">
            <i class="iconfont_Me icon-x iconStyle"></i>
          </div>
        </div>
      </div>
      <span
        class="countText"
        :style="{ color: highlight ? '#52BD68' : '' }"
        v-if="content.childrenCounter != 0 && !showInputBlock"
      >
        (&nbsp;{{ content.childrenCounter }}&nbsp;)
      </span>
    </template>
    <template v-if="foldersType == 'iconMode'">
      <div
        class="folderInner"
        :class="isSearch ? 'searchStyle' : ''"
        v-if="content.nodeType == 0"
        :style="{ overflow: showInputBlock ? '' : 'hidden' }"
      >
        <div class="itemIcon">
          <el-image
            style="height:100%"
            :src="
              content.isDisbale
                ? require('../../../../../../../assets/images/class/folder-icon-disable.png')
                : require('../../../../../../../assets/images/class/folder-icon.png')
            "
            :fit="'fill'"
          ></el-image>
        </div>
        <div class="itemContent">
          <div class="itemTextBox" v-if="!showInputBlock">
            <div
              class="itemName"
              :style="{ color: highlight ? '#52BD68' : '' }"
            >
              {{ showText }}
            </div>
            <div
              class="itemCount"
              v-if="content.childrenCounter != 0"
              :style="{ color: highlight ? '#52BD68' : '' }"
            >
              (&nbsp;{{ content.childrenCounter }}&nbsp;)
            </div>
          </div>
          <div class="itemPosition" v-else>
            <input
              class="itemInput"
              type="text"
              v-model="inputContent"
              ref="itemInput"
            />
            <div class="buttonBox">
              <div
                v-waves
                class="commonButton confirm"
                @click.stop="changeName"
              >
                <i class="iconfont_Me icon-check iconStyle"></i>
              </div>
              <div v-waves class="commonButton" @click.stop="closeInput">
                <i class="iconfont_Me icon-x iconStyle"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="item-info" v-if="isSearch" :title="routerPreview">
          {{ routerPreview }}
        </div>
      </div>
      <div
        class="groupInner"
        :class="isSearch ? 'searchStyle' : ''"
        v-if="content.nodeType == 1"
        :title="showText"
      >
        <div class="innerBox">
          <div class="itemIcon">
            <el-image
              v-if="content.shortInfoVo"
              style="height:100%;width:100%;border-radius: 50%"
              :src="content.shortInfoVo.profilePhoto"
              :fit="'fill'"
            ></el-image>
            <div
              v-if="!content.shortInfoVo"
              style="height:100%;width:100%;border-radius: 50%;background: #E8E8E8"
            ></div>
          </div>
          <div
            class="itemContent"
            :style="{ color: highlight ? '#52BD68' : '' }"
            v-if="!showInputBlock"
          >
            {{ showText }}
          </div>
          <div class="itemPosition" v-else>
            <input
              class="itemInput"
              type="text"
              v-model="inputContent"
              ref="itemInput"
            />
            <div class="buttonBox">
              <div
                v-waves
                class="commonButton confirm"
                @click.stop="changeName"
              >
                <i class="iconfont_Me icon-check iconStyle"></i>
              </div>
              <div v-waves class="commonButton" @click.stop="closeInput">
                <i class="iconfont_Me icon-x iconStyle"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="item-info" v-if="isSearch" :title="routerPreview">
          {{ routerPreview }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { modifyClassName } from "@/api/newVersion/discussionGroupClassification";
import * as IMApi from "@/api/im";
export default {
  props: {
    isSearch: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
    modifyMode: {
      type: Boolean,
      default: true,
    },
    showUngroupedGroups: {
      type: Boolean,
      default: false,
    },
    // 展示方式 listMode iconMode
    foldersType: {
      type: String,
      default: "listMode",
    },
  },
  data() {
    return {
      showInputBlock: false,
      inputContent: "",
      clickTimer: null,
    };
  },
  watch: {
    showInputFolderId: {
      handler(val, old) {
        if (val && val == this.content.id && val != old) {
          this.showInput();
        } else {
          this.showInputBlock = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    showText() {
      let text = "";
      switch (this.content.nodeType) {
        case 0:
          text = this.content.nodeData;
          break;
        case 1:
          text = this.content.shortInfoVo
            ? this.content.shortInfoVo.chatName
            : this.content.nodeData;
          break;
        case 2:
          text = this.content.shortInfoVo
            ? this.content.shortInfoVo.chatName
            : this.content.nodeData;
          break;
      }
      return text;
    },
    routerPreview() {
      let text = `./${this.$t("myCategory")}`;
      if (this.content.parent) {
        text = this.content.parent.nodeData
          ? "../" + this.content.parent.nodeData
          : `./${this.$t("myCategory")}`;
      }
      return text;
    },
    showInputFolderId() {
      return this.$store.state.myClassification.showInputFolderId;
    },
    highlightFolderIds() {
      return this.$store.state.myClassification.highlightFolderIds;
    },
    highlight() {
      let needHighlight = false;
      if (this.highlightFolderIds.length != 0) {
        needHighlight = this.highlightFolderIds.some(
          (item) => item == this.content.id
        );
      }
      return needHighlight;
    },
  },
  mounted() {
    this.inputContent = this.showText;
  },
  methods: {
    async showInput() {
      if (!this.modifyMode) {
        return;
      }
      if (this.showUngroupedGroups) {
        return;
      }
      if (this.clickTimer) {
        clearTimeout(this.clickTimer);
      }
      this.showInputBlock = true;
      this.$nextTick(async () => {
        this.$refs.itemInput.focus();
        await this.$nextTick();
        this.$refs.itemInput.select();
      });
    },
    closeInput() {
      this.$store.commit("setShowInputFolderId", null);
    },
    async changeName() {
      const { content, inputContent } = this;
      let params, result, req;
      switch (content.nodeType) {
        case 0:
          params = {
            nodeData: inputContent,
            id: content.id,
          };
          result = await modifyClassName(content.id, params);
          if (result.code === 200) {
            this.updateRefreshFlags(content.id, inputContent, content);
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
          break;
        case 1:
          req = await IMApi.updateGroupName(content.nodeData, inputContent);
          if (req.code === 1) {
            if (
              this.$store.state.myClassification.existingClassifiedGroupInfo
            ) {
              let existingClassifiedGroupInfo = _.cloneDeep(
                this.$store.state.myClassification.existingClassifiedGroupInfo
              );
              existingClassifiedGroupInfo.displayName = inputContent;
              this.$store.commit(
                "setExistingClassifiedGroupInfo",
                existingClassifiedGroupInfo
              );
            }
            this.updateRefreshFlags(content.id, inputContent, content);
          } else {
            this.$message({
              type: "error",
              message: req.message,
            });
          }
          break;
      }
      this.closeInput();
    },
    // 修改成功后静态刷新列表这个名字
    updateRefreshFlags(id, input, content) {
      // 设置高亮显示ID
      this.$store.commit("setHighlightFolderIds", [content.id]);
      // 存储需要刷新的名称
      this.$store.commit("setRefreshFolderInfo", { id, input });
      // 刷新文件夹列表
      this.$store.commit("setRefreshFoldersList", true);
      // 通知IM刷新records
      this.$store.commit("setNeedRefreshSeamlessRefresh", true);
    },
  },
};
</script>
<style lang="stylus" scoped>
.iconMode
  width: 100%;
  height: 100%;
  cursor: pointer;
  .searchStyle
    border: 7px solid #FAFAFA;
    .item-info
      margin-top: 10px;
      font-size: 12px;
      background: #FAFAFA;
      width: 100%;
      padding: 8px 0;
      box-sizing: border-box;
      overflow: hidden
      white-space: nowrap;
      text-overflow: ellipsis;
  .folderInner
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .itemIcon
      width: 100%
      height: 32px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    .itemContent
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-top: 10px;
      position: relative;
      width: 100%;
      .itemTextBox
        width: 100%;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        .itemName
          font-size: 12px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          width: fit-content;
        .itemCount
          display: inline-block;
      .itemPosition
        font-size: 14px;
        padding-left: 8px;
        max-width: 180px;
        height: 22px;
        position: relative;
        .itemInput
          height: 22px;
          width: 80px;
          font-size: 12px;
          border-radius: 5px;
          border: 1px solid #63cb5f;
        .buttonBox
          height: 22px;
          width: 80px;
          position: absolute;
          right: -88px;
          top: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .commonButton
            height: 100%;
            width: 35px;
            border-radius: 5px;
            background: #A1A4A9;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .iconStyle
              font-size: 14px;
              color: #FFFFFF;
          .confirm
            background: #66C871;
  .groupInner
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .innerBox
      width: 100%;
      height: 100%;
      padding: 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      position: relative;
      .itemIcon
        width: 35px;
        height: 35px;
        flex-shrink: 0;
      .itemContent
        flex: 1;
        min-width: 0;
        max-width: calc(100% - 60px);
        font-size: 14px;
        overflow: hidden
        white-space: nowrap;
        text-overflow: ellipsis;
        box-sizing: border-box;
        padding-left: 10px;
        color: #000000;
        height: 22px;
        line-height: 22px;
      .itemPosition
        font-size: 14px;
        padding-left: 8px;
        max-width: 300px;
        position: relative;
        height: 22px;
        .itemInput
          height: 22px;
          width: 160px;
          border-radius: 5px;
          border: 1px solid #63cb5f;
        .buttonBox
          height: 22px;
          width: 80px;
          position: absolute;
          right: -88px;
          top: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .commonButton
            height: 100%;
            width: 35px;
            border-radius: 5px;
            background: #A1A4A9;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .iconStyle
              font-size: 14px;
              color: #FFFFFF;
          .confirm
            background: #66C871;
.listMode
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  max-width: calc(100% - 60px);
  position: relative;
  .itemIcon
    min-height: 14px;
    min-width: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  .itemText
    font-size: 14px;
    padding-left: 8px;
    overflow hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 22px;
    line-height: 22px;
  .itemPosition
    font-size: 14px;
    padding-left: 8px;
    max-width: 300px;
    height: 22px;
    position: relative;
    .itemInput
      height: 22px;
      width: 200px;
      border-radius: 5px;
      border: 1px solid #63cb5f;
    .buttonBox
      height: 22px;
      width: 80px;
      position: absolute;
      right: -88px;
      top: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .commonButton
        height: 100%;
        width: 35px;
        border-radius: 5px;
        background: #A1A4A9;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .iconStyle
          font-size: 14px;
          color: #FFFFFF;
      .confirm
        background: #66C871;
  .countText
    font-size: 14px;
</style>
