<template>
  <div class="folderListBox">
    <div
      class="folderList"
      v-scroll="{
        distance: 100,
        onBottom: loadData,
      }"
      ref="folderList"
    >
      <template v-if="folders.length != 0">
        <div
          v-if="foldersType == 'listMode' || showUngroupedGroups"
          class="listStyleBox"
          :style="folderStyle"
          v-for="node in folders"
          :key="node.refreshKey"
        >
          <TreeNode
            :node="node"
            :showUngroupedGroups="showUngroupedGroups"
            :operatingMenu="operatingMenu"
            :foldersType="foldersType"
            :isSearch="isSearch"
            :isMoving="isMoving"
            @selectThisGroupMenuItem="selectThisGroupMenuItem"
            @selectThis="selectThis"
            :modifyMode="modifyMode"
          ></TreeNode>
        </div>
        <div
          v-if="foldersType == 'iconMode' && !showUngroupedGroups"
          class="iconStyleBox"
        >
          <div class="commonBox">
            <div
              class="folderItem"
              v-for="node in newFolders.folders"
              :key="node.refreshKey"
              :style="{ height: isSearch ? 'auto' : '106px' }"
              :class="{
                folderActive:
                  node.id == activatedFolderId && modifyMode && !isMoving,
              }"
              @click.stop="handleClick(node)"
            >
              <ClassItem
                :content="node"
                :foldersType="foldersType"
                :isSearch="isSearch"
                :modifyMode="modifyMode"
              ></ClassItem>
              <div
                class="operationArea"
                v-if="modifyMode && showInputFolderId != node.id"
                :style="{
                  top: isSearch ? '13px' : '6px',
                  right: isSearch ? '20px' : '10px',
                }"
              >
                <el-popover
                  placement="bottom"
                  width="60"
                  popper-class="commonPopoverForPersonalCenter"
                  trigger="hover"
                >
                  <div class="operatingMenuBox">
                    <div
                      class="operatingMenuIconBox"
                      v-for="(c, index) in handlerOperatingMenu(node)"
                      :key="index"
                      @click.stop="selectThisGroupMenuItem(index, node)"
                    >
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="c.tooltipContent"
                        placement="left"
                      >
                        <span
                          v-if="c.iconClassName"
                          class="iconfont_Me iconStyle"
                          :class="c.iconClassName"
                        ></span>
                      </el-tooltip>
                    </div>
                  </div>
                  <div
                    style="width: 16px;height: 16px;display:flex;align-items: center;justify-content: center; cursor: pointer"
                    slot="reference"
                  >
                    <el-image
                      style="width: 16px;height:3px;"
                      :src="
                        require('../../../../../../assets/images/newPersonalCenter/openPopoverIcon.png')
                      "
                      :fit="'fill'"
                    ></el-image>
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
          <div class="commonBox">
            <div
              class="groupItem"
              v-for="node in newFolders.groups"
              :key="node.refreshKey"
              :class="{
                folderActive:
                  node.id == activatedFolderId && modifyMode && !isMoving,
              }"
              @click.stop="handleClick(node)"
            >
              <ClassItem
                :content="node"
                :foldersType="foldersType"
                :isSearch="isSearch"
                :modifyMode="modifyMode"
              ></ClassItem>
              <div
                class="operationArea"
                v-if="modifyMode && showInputFolderId != node.id"
                :style="{
                  top: isSearch ? '42px' : '50%',
                  right: isSearch ? '20px' : '10px',
                }"
              >
                <el-popover
                  placement="bottom"
                  width="60"
                  popper-class="commonPopoverForPersonalCenter"
                  trigger="hover"
                >
                  <div class="operatingMenuBox">
                    <div
                      class="operatingMenuIconBox"
                      v-for="(c, index) in handlerOperatingMenu(node)"
                      :key="index"
                      @click.stop="selectThisGroupMenuItem(index, node)"
                    >
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="c.tooltipContent"
                        placement="left"
                      >
                        <span
                          v-if="c.iconClassName"
                          class="iconfont_Me iconStyle"
                          :class="c.iconClassName"
                        ></span>
                      </el-tooltip>
                    </div>
                  </div>
                  <div
                    style="width: 16px;height: 16px;display:flex;align-items: center;justify-content: center; cursor: pointer"
                    slot="reference"
                  >
                    <el-image
                      style="width: 16px;height:3px;"
                      :src="
                        require('../../../../../../assets/images/newPersonalCenter/openPopoverIcon.png')
                      "
                      :fit="'fill'"
                    ></el-image>
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else-if="folders.length == 0 && !loading" class="no-data">
        <img src="../../../../../../assets/images/class/no-data-img.png" />
      </div>
    </div>
    <div
      v-if="loading && refreshLoading"
      class="loading-overlay"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      :element-loading-background="loadingBackgroundColor"
    ></div>
  </div>
</template>
<script>
import TreeNode from "./treeNode";
import ClassItem from "./treeNode/classItem.vue";
export default {
  name: "Folders",
  components: {
    TreeNode,
    ClassItem,
  },
  props: {
    // 是否是搜索模式
    isSearch: {
      type: Boolean,
      default: false,
    },
    // 可修改模式
    modifyMode: {
      type: Boolean,
      default: true,
    },
    // 正在移动
    isMoving: {
      type: Boolean,
      default: false,
    },
    folders: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showUngroupedGroups: {
      type: Boolean,
      default: false,
    },
    operatingMenu: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 展示方式 listMode iconMode
    foldersType: {
      type: String,
      default: "listMode",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadDataDisabled: false,
      loadingBackgroundColor: "#FFFFFF",
      // 用于更新loading背景
      refreshLoading: true,
      inputTimer: null,
      clickCount: 0,
    };
  },
  watch: {
    showUngroupedGroups: {
      async handler(val, old) {
        if (val != old && val) {
          this.$store.commit("setActivatedFolderId", null);
          await this.changeBackgroundColor("rgb(247,247,247,0.2)");
        } else {
          await this.changeBackgroundColor("rgba(255, 255, 255, 0.2)");
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.inputTimer) {
      clearTimeout(this.inputTimer);
    }
  },
  computed: {
    highlightFolderIds() {
      return this.$store.state.myClassification.highlightFolderIds;
    },
    showInputFolderId() {
      return this.$store.state.myClassification.showInputFolderId;
    },
    activatedFolderId() {
      return this.$store.state.myClassification.activatedFolderId;
    },
    folderStyle() {
      return {
        width: this.showUngroupedGroups ? "100%" : "50%",
      };
    },
    newFolders() {
      const newFolders = {
        folders: [],
        groups: [],
        others: [],
      };
      if (this.foldersType == "iconMode") {
        this.folders.forEach((entry) => {
          switch (entry.nodeType) {
            case 0:
              newFolders.folders.push(entry);
              break;
            case 1:
              newFolders.groups.push(entry);
              break;
            default:
              newFolders.others.push(entry);
              break;
          }
        });
      }
      return newFolders;
    },
    currentClickNode() {
      return this.$store.state.myClassification.currentClickNode;
    },
    clickRecord() {
      return {
        clickCount: this.clickCount,
        node: this.currentClickNode,
      };
    },
  },
  methods: {
    handleClick(node) {
      if (this.clickRecord.node && this.clickRecord.node == node.id) {
        this.clickCount++;
      } else {
        this.$store.commit("setCurrentClickNode", node.id);
        this.clickCount = 1;
      }
      if (this.clickRecord.clickCount === 1) {
        if (this.isMoving || !this.modifyMode) {
          this.inputTimer = setTimeout(() => {
            this.resetClickRecord();
          }, 800);
          return;
        }
        if (this.showInputFolderId == node.id) {
          this.resetClickRecord();
          return;
        }
        this.$store.commit("setShowInputFolderId", null);
        this.inputTimer = setTimeout(() => {
          this.activateNode(node);
          this.resetClickRecord();
        }, 800);
      } else if (this.clickRecord.clickCount === 2) {
        clearTimeout(this.inputTimer);
        this.resetClickRecord();
        this.selectThis(node);
      }
    },
    resetClickRecord() {
      this.clickCount = 0;
      this.$store.commit("setCurrentClickNode", null);
    },
    activateNode(node) {
      if (this.activatedFolderId == node.id) {
        this.$store.commit("setShowInputFolderId", node.id);
        clearTimeout(this.inputTimer);
        this.inputTimer = null;
      } else if (this.activatedFolderId != node.id) {
        if (this.showUngroupedGroups) {
          this.selectThis(node);
        } else {
          this.$store.commit("setActivatedFolderId", node.id);
        }
      }
    },
    selectThis(content) {
      this.$store.commit("setShowInputFolderId", null);
      this.$store.commit("setActivatedFolderId", null);
      this.$emit("selectThis", content);
    },
    handlerOperatingMenu(node) {
      let operatingMenu = _.cloneDeep(this.operatingMenu);
      if (node.nodeType == 1) {
        operatingMenu.unshift({
          iconClassName: "icon-a-zu5517",
          tooltipContent: this.$t("copy"),
        });
      }
      return operatingMenu;
    },
    async changeBackgroundColor(newColor) {
      this.loadingBackgroundColor = newColor;
      this.$nextTick(() => {
        this.refreshLoading = !this.refreshLoading;
        this.$nextTick(() => {
          this.refreshLoading = !this.refreshLoading;
        });
      });
    },
    selectThisGroupMenuItem(index, item) {
      this.$emit("selectThisGroupMenuItem", index, item);
    },
    loadData() {
      if (this.loadDataDisabled) {
        return;
      }
      this.loadDataDisabled = true;
      this.$emit("loadData", () => {
        this.loadDataDisabled = false;
      });
    },
  },
};
</script>
<style lang="stylus">
.folderListBox
  .loading-overlay
    .el-loading-mask
      .el-loading-spinner
        margin-top: 0 !important;
        transform: translate3d(0,-50%,0) !important;
</style>
<style lang="stylus" scoped>
@import '~styles/utils/index';
.folderListBox
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  .loading-overlay
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    :v-deep .el-loading-mask
      .el-loading-spinner
        margin-top: 0 !important;
  .folderList
    width: 100%;
    min-height: 0;
    flex: 1;
    flex-shrink: 0;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 0 50px 10px 30px;
    scrollbar-light();
    flex: 1;
    .listStyleBox
      height: auto;
      display: inline-block;
    .iconStyleBox
      width: 100%;
      height: auto;
      overflow: hidden;
      .commonBox
        width: 100%;
        height: auto;
        overflow: hidden;
        .folderActive
          border: 1px solid #9cd1ff !important;
          background: #d8eaf9 !important;
          border-radius: 5px;
        .folderItem
          display: inline-block;
          width: 138px;
          margin: 0 20px 20px 0;
          transition: all .5s;
          position: relative;
          border: 1px solid transparent;
          &:hover
            cursor: pointer;
            border-radius: 5px;
            background: rgba(216,234,249,1)
          .operationArea
            position: absolute;
            top: 6px;
            right: 10px;
        .groupItem
          display: inline-block;
          width: calc(50% - 5px);
          transition: all .5s;
          position: relative;
          margin-right: 10px;
          margin-bottom: 10px
          border: 1px solid transparent;
          &:nth-child(2n)
            margin-right: 0 !important;
          &:hover
            cursor: pointer;
            border-radius: 5px;
            background: rgba(216,234,249,1)
          .operationArea
            position: absolute;
            transform: translate3d(0,-50%,0);
    .no-data
      border-top: none;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
</style>
