var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "folderListBox" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "scroll",
            rawName: "v-scroll",
            value: {
              distance: 100,
              onBottom: _vm.loadData
            },
            expression:
              "{\n      distance: 100,\n      onBottom: loadData,\n    }"
          }
        ],
        ref: "folderList",
        staticClass: "folderList"
      },
      [
        _vm.folders.length != 0
          ? [
              _vm._l(_vm.folders, function(node) {
                return _vm.foldersType == "listMode" || _vm.showUngroupedGroups
                  ? _c(
                      "div",
                      {
                        key: node.refreshKey,
                        staticClass: "listStyleBox",
                        style: _vm.folderStyle
                      },
                      [
                        _c("TreeNode", {
                          attrs: {
                            node: node,
                            showUngroupedGroups: _vm.showUngroupedGroups,
                            operatingMenu: _vm.operatingMenu,
                            foldersType: _vm.foldersType,
                            isSearch: _vm.isSearch,
                            isMoving: _vm.isMoving,
                            modifyMode: _vm.modifyMode
                          },
                          on: {
                            selectThisGroupMenuItem:
                              _vm.selectThisGroupMenuItem,
                            selectThis: _vm.selectThis
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              }),
              _vm.foldersType == "iconMode" && !_vm.showUngroupedGroups
                ? _c("div", { staticClass: "iconStyleBox" }, [
                    _c(
                      "div",
                      { staticClass: "commonBox" },
                      _vm._l(_vm.newFolders.folders, function(node) {
                        return _c(
                          "div",
                          {
                            key: node.refreshKey,
                            staticClass: "folderItem",
                            class: {
                              folderActive:
                                node.id == _vm.activatedFolderId &&
                                _vm.modifyMode &&
                                !_vm.isMoving
                            },
                            style: { height: _vm.isSearch ? "auto" : "106px" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleClick(node)
                              }
                            }
                          },
                          [
                            _c("ClassItem", {
                              attrs: {
                                content: node,
                                foldersType: _vm.foldersType,
                                isSearch: _vm.isSearch,
                                modifyMode: _vm.modifyMode
                              }
                            }),
                            _vm.modifyMode && _vm.showInputFolderId != node.id
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "operationArea",
                                    style: {
                                      top: _vm.isSearch ? "13px" : "6px",
                                      right: _vm.isSearch ? "20px" : "10px"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "bottom",
                                          width: "60",
                                          "popper-class":
                                            "commonPopoverForPersonalCenter",
                                          trigger: "hover"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "operatingMenuBox" },
                                          _vm._l(
                                            _vm.handlerOperatingMenu(node),
                                            function(c, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "operatingMenuIconBox",
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.selectThisGroupMenuItem(
                                                        index,
                                                        node
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          c.tooltipContent,
                                                        placement: "left"
                                                      }
                                                    },
                                                    [
                                                      c.iconClassName
                                                        ? _c("span", {
                                                            staticClass:
                                                              "iconfont_Me iconStyle",
                                                            class:
                                                              c.iconClassName
                                                          })
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "16px",
                                              height: "16px",
                                              display: "flex",
                                              "align-items": "center",
                                              "justify-content": "center",
                                              cursor: "pointer"
                                            },
                                            attrs: { slot: "reference" },
                                            slot: "reference"
                                          },
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "16px",
                                                height: "3px"
                                              },
                                              attrs: {
                                                src: require("../../../../../../assets/images/newPersonalCenter/openPopoverIcon.png"),
                                                fit: "fill"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "commonBox" },
                      _vm._l(_vm.newFolders.groups, function(node) {
                        return _c(
                          "div",
                          {
                            key: node.refreshKey,
                            staticClass: "groupItem",
                            class: {
                              folderActive:
                                node.id == _vm.activatedFolderId &&
                                _vm.modifyMode &&
                                !_vm.isMoving
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleClick(node)
                              }
                            }
                          },
                          [
                            _c("ClassItem", {
                              attrs: {
                                content: node,
                                foldersType: _vm.foldersType,
                                isSearch: _vm.isSearch,
                                modifyMode: _vm.modifyMode
                              }
                            }),
                            _vm.modifyMode && _vm.showInputFolderId != node.id
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "operationArea",
                                    style: {
                                      top: _vm.isSearch ? "42px" : "50%",
                                      right: _vm.isSearch ? "20px" : "10px"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "bottom",
                                          width: "60",
                                          "popper-class":
                                            "commonPopoverForPersonalCenter",
                                          trigger: "hover"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "operatingMenuBox" },
                                          _vm._l(
                                            _vm.handlerOperatingMenu(node),
                                            function(c, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "operatingMenuIconBox",
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.selectThisGroupMenuItem(
                                                        index,
                                                        node
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          c.tooltipContent,
                                                        placement: "left"
                                                      }
                                                    },
                                                    [
                                                      c.iconClassName
                                                        ? _c("span", {
                                                            staticClass:
                                                              "iconfont_Me iconStyle",
                                                            class:
                                                              c.iconClassName
                                                          })
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "16px",
                                              height: "16px",
                                              display: "flex",
                                              "align-items": "center",
                                              "justify-content": "center",
                                              cursor: "pointer"
                                            },
                                            attrs: { slot: "reference" },
                                            slot: "reference"
                                          },
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "16px",
                                                height: "3px"
                                              },
                                              attrs: {
                                                src: require("../../../../../../assets/images/newPersonalCenter/openPopoverIcon.png"),
                                                fit: "fill"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ]
          : _vm.folders.length == 0 && !_vm.loading
          ? _c("div", { staticClass: "no-data" }, [
              _c("img", {
                attrs: {
                  src: require("../../../../../../assets/images/class/no-data-img.png")
                }
              })
            ])
          : _vm._e()
      ],
      2
    ),
    _vm.loading && _vm.refreshLoading
      ? _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "loading-overlay",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": _vm.loadingBackgroundColor
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }