var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "listArrangement",
      class: {
        folderActive:
          _vm.node.id == _vm.activatedFolderId &&
          _vm.modifyMode &&
          !_vm.isMoving
      },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.handleClick.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "rowBox" },
        [
          _c("ClassItem", {
            attrs: {
              content: _vm.node,
              showUngroupedGroups: _vm.showUngroupedGroups,
              isSearch: _vm.isSearch,
              modifyMode: _vm.modifyMode
            }
          }),
          _c("div", { staticClass: "rowRightBox" }, [
            _vm.isSearch
              ? _c(
                  "div",
                  {
                    staticClass: "item-info",
                    attrs: { title: _vm.routerPreview }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.routerPreview) + "\n      "
                    )
                  ]
                )
              : _vm._e(),
            _vm.showOperationArea
              ? _c(
                  "div",
                  {
                    staticClass: "operationArea",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return (() => {
                          return false
                        }).apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom",
                          width: "60",
                          "popper-class": "commonPopoverForPersonalCenter",
                          trigger: "hover"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "operatingMenuBox" },
                          _vm._l(_vm.handlerOperatingMenu, function(c, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "operatingMenuIconBox",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.selectThisGroupMenuItem(
                                      index,
                                      _vm.node
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: c.tooltipContent,
                                      placement: "left"
                                    }
                                  },
                                  [
                                    c.iconClassName
                                      ? _c("span", {
                                          staticClass: "iconfont_Me iconStyle",
                                          class: c.iconClassName
                                        })
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "16px",
                              height: "16px",
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "center",
                              cursor: "pointer"
                            },
                            attrs: { slot: "reference" },
                            slot: "reference"
                          },
                          [
                            _c("el-image", {
                              staticStyle: { width: "16px", height: "3px" },
                              attrs: {
                                src: require("../../../../../../../assets/images/newPersonalCenter/openPopoverIcon.png"),
                                fit: "fill"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }