import { render, staticRenderFns } from "./classItem.vue?vue&type=template&id=4edf75c9&scoped=true"
import script from "./classItem.vue?vue&type=script&lang=js"
export * from "./classItem.vue?vue&type=script&lang=js"
import style0 from "./classItem.vue?vue&type=style&index=0&id=4edf75c9&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4edf75c9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\main project\\new_front_melinked\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4edf75c9')) {
      api.createRecord('4edf75c9', component.options)
    } else {
      api.reload('4edf75c9', component.options)
    }
    module.hot.accept("./classItem.vue?vue&type=template&id=4edf75c9&scoped=true", function () {
      api.rerender('4edf75c9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/imelink/components/myClassification/components/treeNode/classItem.vue"
export default component.exports