<template>
  <div
    class="listArrangement"
    :class="{
      folderActive: node.id == activatedFolderId && modifyMode && !isMoving,
    }"
    @click.stop="handleClick"
  >
    <div class="rowBox">
      <ClassItem
        :content="node"
        :showUngroupedGroups="showUngroupedGroups"
        :isSearch="isSearch"
        :modifyMode="modifyMode"
      ></ClassItem>
      <div class="rowRightBox">
        <div class="item-info" v-if="isSearch" :title="routerPreview">
          {{ routerPreview }}
        </div>
        <div
          class="operationArea"
          v-if="showOperationArea"
          @click.stop="
            () => {
              return false;
            }
          "
        >
          <el-popover
            placement="bottom"
            width="60"
            popper-class="commonPopoverForPersonalCenter"
            trigger="hover"
          >
            <div class="operatingMenuBox">
              <div
                class="operatingMenuIconBox"
                v-for="(c, index) in handlerOperatingMenu"
                :key="index"
                @click.stop="selectThisGroupMenuItem(index, node)"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="c.tooltipContent"
                  placement="left"
                >
                  <span
                    v-if="c.iconClassName"
                    class="iconfont_Me iconStyle"
                    :class="c.iconClassName"
                  ></span>
                </el-tooltip>
              </div>
            </div>
            <div
              style="width: 16px;height: 16px;display:flex;align-items: center;justify-content: center; cursor: pointer"
              slot="reference"
            >
              <el-image
                style="width: 16px;height:3px;"
                :src="
                  require('../../../../../../../assets/images/newPersonalCenter/openPopoverIcon.png')
                "
                :fit="'fill'"
              ></el-image>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassItem from "./classItem.vue";
export default {
  name: "TreeNode",
  components: {
    ClassItem,
    TreeNode: {
      extends: this, // 继承当前组件的选项
    },
  },
  data() {
    return {
      inputTimer: null,
      clickCount: 0,
    };
  },
  computed: {
    // 只有当没有展开拖动列表并且没有进去搜索状态并且是修改模式并且不是可输入状态的时候才能展示操作窗
    showOperationArea() {
      return (
        !this.showUngroupedGroups &&
        this.modifyMode &&
        this.showInputFolderId != this.node.id
      );
    },
    handlerOperatingMenu() {
      let operatingMenu = _.cloneDeep(this.operatingMenu);
      if (this.node.nodeType == 1) {
        operatingMenu.unshift({
          iconClassName: "icon-a-zu5517",
          tooltipContent: this.$t("copy"),
        });
      }
      return operatingMenu;
    },
    routerPreview() {
      let text = `./${this.$t("myCategory")}`;
      if (this.node.parent) {
        text = this.node.parent.nodeData
          ? "../" + this.node.parent.nodeData
          : `./${this.$t("myCategory")}`;
      }
      return text;
    },
    activatedFolderId() {
      return this.$store.state.myClassification.activatedFolderId;
    },
    showInputFolderId() {
      return this.$store.state.myClassification.showInputFolderId;
    },
    currentClickNode() {
      return this.$store.state.myClassification.currentClickNode;
    },
    clickRecord() {
      return {
        clickCount: this.clickCount,
        node: this.currentClickNode,
      };
    },
  },
  props: {
    isSearch: {
      type: Boolean,
      default: false,
    },
    modifyMode: {
      type: Boolean,
      default: true,
    },
    // 正在移动
    isMoving: {
      type: Boolean,
      default: false,
    },
    node: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showUngroupedGroups: {
      type: Boolean,
      default: false,
    },
    operatingMenu: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  beforeDestroy() {
    if (this.inputTimer) {
      clearTimeout(this.inputTimer);
    }
  },
  methods: {
    handleClick() {
      if (this.clickRecord.node && this.clickRecord.node == this.node.id) {
        this.clickCount++;
      } else {
        this.$store.commit("setCurrentClickNode", this.node.id);
        this.clickCount = 1;
      }
      if (this.clickRecord.clickCount === 1) {
        if (this.isMoving || !this.modifyMode) {
          this.inputTimer = setTimeout(() => {
            this.resetClickRecord();
          }, 800);
          return;
        }
        if (this.showInputFolderId == this.node.id) {
          this.resetClickRecord();
          return;
        }
        this.$store.commit("setShowInputFolderId", null);
        this.inputTimer = setTimeout(() => {
          this.activateNode();
          this.resetClickRecord();
        }, 800);
      } else if (this.clickRecord.clickCount === 2) {
        clearTimeout(this.inputTimer);
        this.resetClickRecord();
        this.selectThis();
      }
    },
    resetClickRecord() {
      this.clickCount = 0;
      this.$store.commit("setCurrentClickNode", null);
    },
    selectThis() {
      this.$store.commit("setShowInputFolderId", null);
      this.$store.commit("setActivatedFolderId", null);
      this.$emit("selectThis", this.node);
    },
    async activateNode() {
      if (this.activatedFolderId == this.node.id) {
        this.$store.commit("setShowInputFolderId", this.node.id);
        clearTimeout(this.inputTimer);
        this.inputTimer = null;
      } else if (this.activatedFolderId != this.node.id) {
        if (!this.showUngroupedGroups) {
          this.$store.commit("setActivatedFolderId", this.node.id);
        }
      }
    },
    selectThisGroupMenuItem(index, item) {
      this.$emit("selectThisGroupMenuItem", index, item);
    },
  },
};
</script>
<style lang="stylus" scoped>
.folderActive
  border: 1px solid #9cd1ff !important;
  background: #d8eaf9 !important;
  border-radius: 5px;
.listArrangement
  width: 100%;
  min-height: 25px;
  overflow: hidden;
  transition: border-radius .5;
  box-sizing: border-box;
  border: 1px solid transparent;
  &:hover
    cursor: pointer;
    border-radius: 5px;
    background: #d8eaf9;
  .rowBox
    height: 25px;
    width: calc(100% - 25px);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    box-sizing: border-box;
    .rowRightBox
      height: 25px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .item-info
        max-width: 60px;
        font-size: 12px;
        color: #333;
        overflow: hidden
        white-space: nowrap;
        text-overflow: ellipsis;
      .operationArea
        margin-left: 10px;
</style>
