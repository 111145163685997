var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.foldersType },
    [
      _vm.foldersType == "listMode"
        ? [
            _c(
              "div",
              { staticClass: "itemIcon" },
              [
                _vm.content.nodeType == 0
                  ? _c("el-image", {
                      staticStyle: { height: "16px", width: "20px" },
                      attrs: {
                        src: _vm.content.isDisbale
                          ? require("../../../../../../../assets/images/class/folder-icon-disable.png")
                          : require("../../../../../../../assets/images/class/folder-icon.png"),
                        fit: "fill"
                      }
                    })
                  : _vm._e(),
                _vm.content.nodeType == 1 && _vm.content.shortInfoVo
                  ? _c("el-image", {
                      staticStyle: {
                        height: "20px",
                        width: "20px",
                        "border-radius": "50%"
                      },
                      attrs: {
                        src: _vm.content.shortInfoVo.profilePhoto,
                        fit: "fill"
                      }
                    })
                  : _vm._e(),
                _vm.content.nodeType == 1 && !_vm.content.shortInfoVo
                  ? _c("div", {
                      staticStyle: {
                        height: "20px",
                        width: "20px",
                        "border-radius": "50%",
                        background: "#E8E8E8"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            !_vm.showInputBlock
              ? _c(
                  "div",
                  {
                    staticClass: "itemText",
                    style: {
                      "max-width": _vm.showUngroupedGroups ? "200px" : "250px"
                    },
                    attrs: { title: _vm.showText }
                  },
                  [
                    _c(
                      "span",
                      { style: { color: _vm.highlight ? "#52BD68" : "" } },
                      [_vm._v(_vm._s(_vm.showText))]
                    )
                  ]
                )
              : _c("div", { staticClass: "itemPosition" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.inputContent,
                        expression: "inputContent"
                      }
                    ],
                    ref: "itemInput",
                    staticClass: "itemInput",
                    attrs: { type: "text" },
                    domProps: { value: _vm.inputContent },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.inputContent = $event.target.value
                      }
                    }
                  }),
                  _c("div", { staticClass: "buttonBox" }, [
                    _c(
                      "div",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        staticClass: "commonButton confirm",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changeName.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont_Me icon-check iconStyle"
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        staticClass: "commonButton",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.closeInput.apply(null, arguments)
                          }
                        }
                      },
                      [_c("i", { staticClass: "iconfont_Me icon-x iconStyle" })]
                    )
                  ])
                ]),
            _vm.content.childrenCounter != 0 && !_vm.showInputBlock
              ? _c(
                  "span",
                  {
                    staticClass: "countText",
                    style: { color: _vm.highlight ? "#52BD68" : "" }
                  },
                  [
                    _vm._v(
                      "\n      ( " +
                        _vm._s(_vm.content.childrenCounter) +
                        " )\n    "
                    )
                  ]
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm.foldersType == "iconMode"
        ? [
            _vm.content.nodeType == 0
              ? _c(
                  "div",
                  {
                    staticClass: "folderInner",
                    class: _vm.isSearch ? "searchStyle" : "",
                    style: { overflow: _vm.showInputBlock ? "" : "hidden" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "itemIcon" },
                      [
                        _c("el-image", {
                          staticStyle: { height: "100%" },
                          attrs: {
                            src: _vm.content.isDisbale
                              ? require("../../../../../../../assets/images/class/folder-icon-disable.png")
                              : require("../../../../../../../assets/images/class/folder-icon.png"),
                            fit: "fill"
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "itemContent" }, [
                      !_vm.showInputBlock
                        ? _c("div", { staticClass: "itemTextBox" }, [
                            _c(
                              "div",
                              {
                                staticClass: "itemName",
                                style: { color: _vm.highlight ? "#52BD68" : "" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.showText) +
                                    "\n          "
                                )
                              ]
                            ),
                            _vm.content.childrenCounter != 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "itemCount",
                                    style: {
                                      color: _vm.highlight ? "#52BD68" : ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            ( " +
                                        _vm._s(_vm.content.childrenCounter) +
                                        " )\n          "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _c("div", { staticClass: "itemPosition" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.inputContent,
                                  expression: "inputContent"
                                }
                              ],
                              ref: "itemInput",
                              staticClass: "itemInput",
                              attrs: { type: "text" },
                              domProps: { value: _vm.inputContent },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.inputContent = $event.target.value
                                }
                              }
                            }),
                            _c("div", { staticClass: "buttonBox" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    { name: "waves", rawName: "v-waves" }
                                  ],
                                  staticClass: "commonButton confirm",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.changeName.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "iconfont_Me icon-check iconStyle"
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    { name: "waves", rawName: "v-waves" }
                                  ],
                                  staticClass: "commonButton",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.closeInput.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont_Me icon-x iconStyle"
                                  })
                                ]
                              )
                            ])
                          ])
                    ]),
                    _vm.isSearch
                      ? _c(
                          "div",
                          {
                            staticClass: "item-info",
                            attrs: { title: _vm.routerPreview }
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.routerPreview) +
                                "\n      "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm.content.nodeType == 1
              ? _c(
                  "div",
                  {
                    staticClass: "groupInner",
                    class: _vm.isSearch ? "searchStyle" : "",
                    attrs: { title: _vm.showText }
                  },
                  [
                    _c("div", { staticClass: "innerBox" }, [
                      _c(
                        "div",
                        { staticClass: "itemIcon" },
                        [
                          _vm.content.shortInfoVo
                            ? _c("el-image", {
                                staticStyle: {
                                  height: "100%",
                                  width: "100%",
                                  "border-radius": "50%"
                                },
                                attrs: {
                                  src: _vm.content.shortInfoVo.profilePhoto,
                                  fit: "fill"
                                }
                              })
                            : _vm._e(),
                          !_vm.content.shortInfoVo
                            ? _c("div", {
                                staticStyle: {
                                  height: "100%",
                                  width: "100%",
                                  "border-radius": "50%",
                                  background: "#E8E8E8"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      !_vm.showInputBlock
                        ? _c(
                            "div",
                            {
                              staticClass: "itemContent",
                              style: { color: _vm.highlight ? "#52BD68" : "" }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.showText) +
                                  "\n        "
                              )
                            ]
                          )
                        : _c("div", { staticClass: "itemPosition" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.inputContent,
                                  expression: "inputContent"
                                }
                              ],
                              ref: "itemInput",
                              staticClass: "itemInput",
                              attrs: { type: "text" },
                              domProps: { value: _vm.inputContent },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.inputContent = $event.target.value
                                }
                              }
                            }),
                            _c("div", { staticClass: "buttonBox" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    { name: "waves", rawName: "v-waves" }
                                  ],
                                  staticClass: "commonButton confirm",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.changeName.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "iconfont_Me icon-check iconStyle"
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    { name: "waves", rawName: "v-waves" }
                                  ],
                                  staticClass: "commonButton",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.closeInput.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont_Me icon-x iconStyle"
                                  })
                                ]
                              )
                            ])
                          ])
                    ]),
                    _vm.isSearch
                      ? _c(
                          "div",
                          {
                            staticClass: "item-info",
                            attrs: { title: _vm.routerPreview }
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.routerPreview) +
                                "\n      "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }